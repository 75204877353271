import { useEffect, useState } from 'react';
import { AuthService } from '../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const SignInOidc = () => {
  const authService = new AuthService();
  const navigate = useNavigate();

  const initialErrorState = {
    error: false,
    errorMessage: "Ekki tókst að skrá þig inn."
  };

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(initialErrorState);

  // Log user in and redirect to offer market home page.
  const handleLoginChange = async () => {
    try {
      await authService.completeAuthentication();
      if (authService.isLoggedIn()) {
        navigate('/offer-market');
      }
      else {
        authService.renewToken();
      }
    } catch (err) {
      setState({
        error: true,
        errorMessage: "Ekki tókst að skrá þig inn."
      });
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // If we're already logged in, redirect to the offer market home page.
    // This is to prevent the page to crash when user tries to back out of page.
    if (sessionStorage.getItem('initialRender')) { 
      navigate('/offer-market');
      return;
    }
    handleLoginChange();
    sessionStorage.setItem('initialRender', 'true');
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#f4f4f4"
      gap={2}
    >
      <p>Skrái þig inn...</p>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {state.error && (
            <p style={{ color: 'red', fontWeight: 'bold' }}>{state.errorMessage}</p>
          )}
        </>
      )}
    </Box>
  );
};

export default SignInOidc;
