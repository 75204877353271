import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

/**
 * This file defines a SnackbarMessage component, which is a reusable component used to display messages in a snackbar alert to the user. 
 * The snackbar is primarily used for displaying success messages, error messages, information, and warnings.
 * 
 * The SnackbarMessage component receives the following props:
 * - message: The message to be displayed.
 * - type: The type of the message, which can be either 'success', 'error', 'info', or 'warning'.
 * - open: A boolean that determines whether the snackbar is visible or not.
 * - handleClose: A function that will be called to close the snackbar.
 * 
 * The component auto-hides after 6000 milliseconds (6 seconds), and it can also be closed manually by the user.
 */

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type SnackbarMessageProps = {
  message: string;
  type: 'success' | 'error' | 'info' | 'warning';
  open: boolean;
  handleClose: (event?: React.SyntheticEvent | Event, reason?: string) => void;
};

export default function SnackbarMessage({ message, type, open, handleClose }: SnackbarMessageProps) {

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}
