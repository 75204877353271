import { Typography, Box } from '@mui/material';
import { MESSAGES } from '../Services/MessagesTexts';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import OfferMarket from '../Models/OfferMarket'
import { marketOpenDates } from '../Services/DateService';

interface MarketDetailsProps {
  offerMarket: OfferMarket;
}

/**
 * `MarketDetails` is a React functional component that displays information about an offer market.
 * It receives a single prop, `offerMarket`, which is an object with details about a market's status and rules.
 * The component uses responsive design to adjust text sizes for different devices and provides a
 * message about the market status, which could be open, closed, or displaying results.
 * It also shows the minimum offer required for participation if applicable.
 */
const MarketDetails: React.FC<MarketDetailsProps> = ({ offerMarket }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const { validFrom, validTo, minimumOffer } = offerMarket;

  let marketStatusMessage = "";
  if (!offerMarket.isClosed && validFrom && validTo) {
    marketStatusMessage = marketOpenDates(validFrom, validTo);
  } else {
    marketStatusMessage = MESSAGES.MARKET_CLOSED;
  }

  const styles = {
    header: {
      fontSize: isMobile ? '1.3rem' : '1.1rem',
    },
    infoText: {
      fontSize: isMobile ? '1.1rem' : '0.9rem',
    },
  };

  return (
    <Box>
      <Typography style={styles.header} align="center" gutterBottom>
        {marketStatusMessage}
      </Typography>

      <Typography style={styles.infoText} variant="body1" align="center" gutterBottom>
        {
          !offerMarket.isClosed
            ? MESSAGES.OFFER_MARKET_RESULTS
            : MESSAGES.RESULTS_AVAILABLE_ONLINE
        }
      </Typography>

      {
        (!offerMarket.isClosed) && (
          <Typography style={styles.infoText} variant="body1" align="center">
            {
              minimumOffer === 0 || minimumOffer === null
                ? MESSAGES.NO_MINIMUM_OFFER
                : `${MESSAGES.MINIMUM_OFFER} ${minimumOffer}%`
            }
          </Typography>
        )
      }
    </Box>
  );
};

export default MarketDetails;
