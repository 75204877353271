import React, { useEffect } from "react";
import { AuthService } from '../Services/AuthService';

const authService = new AuthService();

function Login() {
  useEffect(() => {
    const login = () => {
      authService.login().then(() => {
        // Login successful
      });
    };
    login();
  }, []);

  return <div></div>;
}
export default Login;
