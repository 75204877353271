// Format number with thousand separators (e.g. 1000000 -> 1.000.000)
export const formatNumber = (num: string) => {
    if (!num) return '';
    return parseInt(num, 10).toLocaleString('de-DE');
};

export const formatNumberFloat = (num: string) => {
    if (!num) return '';
    const parsedNumber = parseFloat(num);
    if (isNaN(parsedNumber)) return '';

    return parsedNumber.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
};
