import { formatNumber } from "./FormatService";

export const MESSAGES = {
    FETCH_ERROR: 'Ekki tókst að sækja gögn frá netþjóninum. Vinsamlegast reynið aftur síðar.',
    SHIP_REGISTRATION_REQUIRED: "Þú átt ekki skráð skip. Vinsamlegast skráðu skip áður en þú býrð til tilboð.",
    OFFER_REGISTRATION_ERROR: "Eitthvað fór úrskeiðis við að skrá tilboð. Vinsamlegast reynið aftur síðar.",
    COD_RATIO_CALCULATION_ERROR: "Ekki tókst að reikna þorskígildishlutfallið. Vinsamlegast reynið aftur síðar.",
    VALUE_COEFFICIENT_CALCULATION_ERROR: "Ekki tókst að reikna virðisstuðul. Vinsamlegast reynið aftur síðar.",
    CALCULATION_ERROR: "Eitthvað fór úrskeiðis við útreikninga. Vinsamlegast reynið aftur síðar.",
    QUANTITY_CALCULATION_ERROR: "Eitthvað fór úrskeiðis við útreikning á magni. Vinsamlegast reynið aftur síðar.",
    MAX_QUOTA: (maxQuota: number, unit: string) => `Hæsta magn sem hægt er að óska eftir er: ${formatNumber(maxQuota.toString())} ${unit}.`,
    LOW_COD_VALUE_RATIO_ERROR: "Þorskígildishlutfall er of lágt.",
    REGISTRATION_ERROR_CORRECTION: "Vinsamlegast leiðrétta villur í innskráningu.",
    NO_OFFERS_IN_MARKETPLACE: "Engin tilboð komin frá þér með völdum síum.",
    OFFER_WITHDRAWN: "Tilboð hefur verið afturkallað.",
    OFFER_REGISTERED: "Tilboð skráð.",
    LINES_PER_PAGE: "Línur á síðu:",
    SELECT_SHIP_TYPE: "Veldu skip útgerðar.",
    SELECT_FISH_TYPE_FOR_PURCHASE: "Veldu fisktegund til kaups.",
    SELECT_QUANTITY_FOR_PURCHASE: "Skráðu magn til kaups. ",
    SELECT_FISH_TYPE_FOR_PAYMENT: "Veldu fisktegund til greiðslu.",
    SELECT_QUANTITY_FOR_PAYMENT: "Skráðu magn til greiðslu.",
    COD_RATIO_TO_PAY: "Hlutfall af þorskígildi sem á að greiða.",
    VALUE_COEFFICIENT_MESSAGE: "Virðisstuðull.",
    QUANTITY_TO_PAY: "Magn til greiðslu.",
    FISH_TYPE_FOR_PAYMENT: "Fisktegund til greiðslu.",
    QUANTITY_FOR_PURCHASE: "Magn til kaups. ",
    WISH_FOR_FISH_TYPE_FOR_PURCHASE: "Óska eftir fisktegund til kaups.",
    MARKET_CLOSED: "Markaðurinn er lokaður.",
    NO_MINIMUM_OFFER: "Ekkert lágmarkstilboð.",
    MINIMUM_OFFER: "Lágmarks tilboð:",
    RESULTS_AVAILABLE_ONLINE: "Niðurstöður tilboðsmarkaða má finna á vefsíðu Fiskistofu og pósthólfi útgerðar.",
    OFFER_MARKET_RESULTS: "Niðurstöður verða birtar á heimasíðu Fiskistofu og í pósthólfi útgerðar.",
    QUANTITY_OVER: "Magn verður að vera yfir 0",
};
