import React, { useState, ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, Switch } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


// Represents either the string 'allFishYears' or any other string. It's intended to differentiate between selecting all fish years and individual years.
export type FishYear = 'allFishYears' | string;

// Represents the state of year selection. It includes an array of selected years and a boolean to determine if all fish years are selected.
export interface FishYearSelection {
    selectedYears: string[];
    allFishYears: boolean;
}

// Defines the props for the `FishYearCheckboxes` component. It includes an array of years, the state and the setter function for the selected years, and a boolean indicating if the open market is empty.
export interface FishYearCheckboxesProps {
    years: string[];
    selectedYearsState: {
        selectedYears: Record<string, boolean>;
        setSelectedYears: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    };
    isOpenMarketEmpty: boolean;
}

// A React functional component that renders a set of checkboxes for each fish year provided. It also includes a switch that allows the user to show or hide offers from older markets.
export const FishYearCheckboxes: React.FC<FishYearCheckboxesProps> = ({ years, selectedYearsState, isOpenMarketEmpty }) => {
    const { selectedYears, setSelectedYears } = selectedYearsState;

    const [showFishYearCheckboxes, setShowFishYearCheckboxes] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Handles changes for individual year checkboxes. It updates the selected year's state based on user interaction. If any checkbox is unchecked, the 'allFishYears' checkbox will also be unchecked.
    const handleSingleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const updatedSelection = {
            ...selectedYears,
            [event.target.name]: event.target.checked
        };

        // If any checkbox is unchecked, uncheck the 'allFishYears' checkbox
        if (!event.target.checked) {
            updatedSelection.allFishYears = false;
        }
        setSelectedYears(updatedSelection);
    };

    // Handles the changes for the 'allFishYears' checkbox. If it's checked, all individual year checkboxes will be checked. If it's unchecked, all checkboxes will be unchecked.
    const handleAllFishYearsCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        let updatedSelection;
        if (event.target.checked) {
            updatedSelection = years.reduce((accum, year) => {
                accum[year] = true;
                return accum;
            }, {} as Record<string, boolean>);
            updatedSelection.allFishYears = true;
        } else {
            updatedSelection = years.reduce((accum, year) => {
                accum[year] = false;
                return accum;
            }, {} as Record<string, boolean>);
            updatedSelection.allFishYears = false;
        }
        setSelectedYears(updatedSelection);
    };

    // Toggles the visibility of the year checkboxes. If the checkboxes are currently displayed and the switch is toggled, all selections are reset.
    const handleSwitchChange = () => {
        if (showFishYearCheckboxes) { // If it's currently showing checkboxes, we're about to hide them.
            const resetSelection = years.reduce((accum, year) => {
                accum[year] = false;
                return accum;
            }, {} as Record<string, boolean>);
            resetSelection.allFishYears = false;
            setSelectedYears(resetSelection);
        }
        setShowFishYearCheckboxes(!showFishYearCheckboxes);
    };

    return (
        <div style={{ height: isMobile ? '' : '2em', display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', marginBottom: '10px' }}>
            {
                !isOpenMarketEmpty && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={showFishYearCheckboxes}
                                onChange={handleSwitchChange}
                            />
                        }
                        label={"Sýna tilboð úr eldri mörkuðum"}
                        style={{ marginRight: '20px' }} // add margin to separate the switch from the checkboxes
                    />
                )
            }

            {(isOpenMarketEmpty || showFishYearCheckboxes) && (
                <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
                    {years.map(year => (
                        <FormControlLabel
                            key={year}
                            control={
                                <Checkbox
                                    checked={selectedYears[year]}
                                    onChange={handleSingleCheckboxChange}
                                    name={year}
                                />
                            }
                            label={`Fiskveiðiár ${year.substring(0, 2)}/${year.substring(2, 4)}`}
                            style={{ marginRight: isMobile ? '0px' : '10px', marginBottom: isMobile ? '10px' : '0px' }}
                        />
                    ))}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedYears.allFishYears}
                                onChange={handleAllFishYearsCheckboxChange}
                                name="allFishYears"
                            />
                        }
                        label="Öll fiskveiðiár"
                    />
                </div>
            )}
        </div>
    );
}

