import dayjs from 'dayjs';

/**
 * 
 * @param fromDate Represents the opening date of the market.
 * @param toDate Represents the closing date of the market.
 * @returns A string representing the interval between the opening and closing dates of the market, formatted in Icelandic.
 */
export function marketOpenDates(fromDate: Date, toDate: Date): string {
    
    const formattedDate = `Markaðurinn opnaði ${formatIcelandicDate(fromDate)} og lokar ${formatIcelandicDate(toDate)}.`;
    return formattedDate;
}

/**
 * 
 * @param date The date object to be formatted.
 * @returns A string representing the input date formatted in the Icelandic linguistic context.
 */
function formatIcelandicDate(date: Date): string {

    const weekday = formatDateWithArticle(date);
    const dayOfMonth = dayjs(date).format('D');
    const month = dayjs(date).format('MMMM');
    const year = dayjs(date).format('YYYY');
    const time = dayjs(date).format('HH:mm');

    return `${weekday} ${dayOfMonth}. ${month} ${year} klukkan ${time}`;
}

/**
 * The date object whose weekday needs to be formatted.
 * @returns A string representing the day of the week from the input date, modified with the appropriate article in Icelandic.
 */
function formatDateWithArticle(date: Date): string {

    const dayWithArticle = dayjs(date).format('dddd').replace('dagur', 'daginn');
    return dayWithArticle;
}
