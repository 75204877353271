import { Dialog, DialogTitle, DialogContent, Divider, Typography, Button, DialogActions, CircularProgress } from '@mui/material';
import Draggable from 'react-draggable';
import React, { useState, useRef } from 'react';
import { withdrawOffer, handleUnauthorizedError } from '../Services/OfferService';
import OfferGridData from '../Models/OfferGridData';
import SnackbarMessage from "./SnackbarMessage";
import Paper, { PaperProps } from '@mui/material/Paper';
import { FaFish } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import { FaShip } from 'react-icons/fa';
import { MdPieChart } from 'react-icons/md';
import { AiOutlineNumber } from 'react-icons/ai';
import { formatNumber } from '../Services/FormatService';

type OffeWithdrawrPopUpProps = {
  open: boolean;
  onClose: (offerWithdraw: boolean) => void;
  offer: OfferGridData;
  tonQuotaTypes: number[];
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function WithdrawOfferPopUp({ open, onClose, offer, tonQuotaTypes: tonQuotaTypes }: OffeWithdrawrPopUpProps) {

  const [snackbarData, setSnackbarData] = React.useState<{
    message: string;
    type: 'success' | 'error' | 'info' | 'warning';
  } | null>(null);

  const [isWithdrawing, setIsWithdrawing] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const isProcessingRef = useRef(false);

  const handleClose = () => {
    onClose(false);
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleWithdraw = async () => {
      if (isProcessingRef.current) return;
  
      isProcessingRef.current = true;
      setIsWithdrawing(true);
  
      try {
          await withdrawOffer(offer.id);
          onClose(true);
      } catch (error) {
          handleUnauthorizedError(error);
          setSnackbarOpen(true);
          setSnackbarData({ message: 'Ekki tókst að afturkalla tilboð. Vinsamlegast reynið aftur síðar.', type: 'error' });
          console.error(error);
      } finally {
          setIsWithdrawing(false);
          isProcessingRef.current = false;
      }
  }
  
  const isOfferQuotaTypeTon = tonQuotaTypes.includes(offer.offerQuotaType);
  const isPaymentQuotaTypeTon = tonQuotaTypes.includes(offer.paymentQuotaType);

  return (
    <div>
      {snackbarData && (
        <SnackbarMessage
          message={snackbarData.message}
          type={snackbarData.type}
          open={snackbarOpen}
          handleClose={closeSnackbar}
        />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{
          cursor: 'move',
          fontSize: '1.5rem',
          fontWeight: 'bold',
          color: '#42a5f5',
        }} id="draggable-dialog-title">
          Ertu viss um að þú viljir afturkalla tilboðið?
        </DialogTitle>
        <DialogContent>
          {isWithdrawing && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 1000,
              pointerEvents: 'none',
            }}>
              <CircularProgress />
            </div>
          )}
          <Divider style={{ marginBottom: '16px' }} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <MdDateRange />
              <Typography variant="subtitle1">
                Dagsetning: {offer.date}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <FaShip />
              <Typography variant="subtitle1">
                Skip heiti: {offer.shipName}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <FaFish />
              <Typography variant="subtitle1">
                Óska eftir tegund: {offer.offerQuotaName}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <AiOutlineNumber />
              <Typography variant="subtitle1">
                Óska eftir magn: {offer.offerQuotaQuantity !== null && offer.offerQuotaQuantity !== undefined ? `${formatNumber(offer.offerQuotaQuantity.toString())} ${isOfferQuotaTypeTon ? 'tonn' : 'kg'}` : ""}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <FaFish />
              <Typography variant="subtitle1">
                Greiða með tegund: {offer.paymentQuotaName}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <AiOutlineNumber />
              <Typography variant="subtitle1">
                Greiða með magn: {offer.paymentQuotaQuantity !== null && offer.paymentQuotaQuantity !== undefined ? `${formatNumber(offer.paymentQuotaQuantity.toString())} ${isPaymentQuotaTypeTon ? 'tonn' : 'kg'}` : ""}
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <MdPieChart />
              <Typography variant="subtitle1">
                Þorskígildihlutfall: {offer.codValueRatio !== null && offer.codValueRatio !== undefined ? formatNumber(offer.codValueRatio.toString()) : ""}
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isWithdrawing}>Loka</Button>
          <Button onClick={handleWithdraw} disabled={isWithdrawing}>Afturkalla</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
