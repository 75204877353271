import { Chip } from '@mui/material';

type StatusChipProps = {
    status: string;
};

type ChipColor = "primary" | "error" | "default" | "success" | "warning" | "secondary" | "info";

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  let color: ChipColor;
  let label;

  switch (status.toUpperCase()) {
    case 'MÓTTEKIÐ':
      color = 'primary';
      label = 'Móttekið';
      break;
    case 'AFTURKALLAÐ':
      color = 'error';
      label = 'Afturkallað';
      break;
    case 'TILBOÐI EKKI TEKIÐ':
      color = 'default';
      label = 'Tilboði ekki tekið';
      break;
    case 'TILBOÐI TEKIÐ AÐ FULLU':
      color = 'success';
      label = 'Tilboði tekið að fullu';
      break;
    case 'TILBOÐI TEKIÐ AÐ HLUTA':
      color = 'warning';
      label = 'Tilboði tekið að hluta';
      break;
    default:
      color = 'default';
      label = status;
  }

  return <Chip label={label} color={color} />;
};

export default StatusChip;
