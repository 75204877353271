/* eslint-disable */

import styled from 'styled-components';

type StyledProps = {
  $isMobile?: boolean;
};

export const OrganizationNameCard = styled.h2`
    margin-block: 0;
    justify-content: space-around;
    display: flex;
    margin-bottom: 0.5em;
`;

export const UserNameCard = styled.h4`
    margin-block: 0;
    justify-content: space-around;
    display: flex;
    margin-bottom: 1em;
`;


export const OrganizationInfoCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserInfoCardContainer = styled.div`
  padding: 1em;
`;

export const UserInfoHeaderContainer = styled.div`
  padding-right: 0.5em;
`;

export const OrganizationNameHeader = styled.h6<StyledProps>`
  margin-block: 0;
  display: flex;
  justify-content: center;
  color: white;
  font-size: ${({ $isMobile }) => ($isMobile ? '1rem' : '1.25rem')};
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  width: 100%;
  text-align: center;
`;

export const StyledLogo = styled.img<StyledProps>`
  padding: 1px;
  border: 2px solid white;
  border-radius: 50%;
  height: ${({ $isMobile }) => ($isMobile ? '2em' : '2em')};
  width: ${({ $isMobile }) => ($isMobile ? '2em' : '2em')};
  background-color: white;
  margin-right:
  display: block;
  object-fit: cover;
  vertical-align: middle;
`;

