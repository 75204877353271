/* eslint-disable */
import { Route, Routes, Navigate } from 'react-router-dom';
import OfferHomePage from './Components/OfferHomePage';
import SignInOidc from './Components/SignInOidc';
import Login from './Components/Login';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />}></Route>
      <Route path="/offer-market" element={<OfferHomePage />}></Route>
      <Route path="/signin-oidc" element={<SignInOidc />}></Route>
      <Route path="*" element={<Navigate to="/offer-market" replace />} />
    </Routes>
  );
}

export default App;