import axios, { AxiosResponse } from 'axios';
import OfferPost from '../Models/OfferPost';
import OfferCodValueRatio from '../Models/CalculateCodValueInput';
import { User } from "oidc-client-ts";
import { AuthService } from '../Services/AuthService';
import Offer from '../Models/Offer';
import OfferDetails from '../Models/OfferDetails';
import CodValueResult from '../Models/CodValueResult';
import { MarketAndInfoResponse } from '../Models/MarketAndInfoResponse'
import { CompanyOffersParams } from '../Models/CompanyOffersParams'

const authService = new AuthService();
const BASE_API_URL = process.env.REACT_APP_BASE_API_URL + 'offers/' || '';

export const handleUnauthorizedError = (error: unknown): void => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      // Unauthorized error - log out user
      authService.logout().then(() => {
        // Log out successful
      });
    }
  }
}

/**
 * Fetches the details of the currently open market, available fish years and quota types in tons from the server.
 * @returns {Promise<MarketAndInfoResponse>} - A Promise that resolves to an object 
 * containing details about the currently open market and the available fish years.
 * @throws Will throw an error if retrieval fails or if unable to reach the API.
 */
export async function getMarketAndInfo(): Promise<MarketAndInfoResponse> {
  try {
    const user = await authService.getUser();
    const url = `${BASE_API_URL}market-and-info`;
    const response = await axios.get<MarketAndInfoResponse>(url, {
      headers: {
        Authorization: authService.getAuthorizationHeaderValue(user)
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Fetches company offers based on the provided market filter.
 * @param {Boolean} allFishYears - Should we show all fish years.
 * @param {number[]} selectedFishYears - Array of selected fish years.
 * @returns {Promise<Offer[]>} - A Promise that resolves to a list of company offers.
 * @throws Will throw an error if retrieval fails or if unable to reach the API.
 */
export async function getCompanyOffers(allFishYears: boolean, selectedFishYears: string[]): Promise<Offer[]> {
  try {
    const user = await authService.getUser();
    const url = `${BASE_API_URL}company-offers`;
    const params: CompanyOffersParams = {
      allFishYears: allFishYears,
    };

    if (selectedFishYears.length > 0) {
      params.selectedFishYears = `${selectedFishYears.join(',')}`;
    } else {
      params.selectedFishYears = ``
    }
    const response = await axios.get<Offer[]>(url, {
      headers: {
        Authorization: authService.getAuthorizationHeaderValue(user)
      },
      params: params
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Retrieves the newest market details and options for the form.
 * @returns {Promise<OfferDetails>} A promise that resolves to the newest market details and options.
 * @throws Will throw an error if retrieval fails or if unable to reach the API.
 */
export async function getOfferMarketDetails(): Promise<OfferDetails> {
  try {
    const user = await authService.getUser();
    if (!user?.profile?.nationalId) throw new Error("User profile or National ID is not available");
    const url = `${BASE_API_URL}market-details`;
    const response = await axios.get<OfferDetails>(url, {
      headers: {
        Authorization: authService.getAuthorizationHeaderValue(user)
      }
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Withdraws an offer by its ID.
 * @param {number} offerId - The unique identifier of the offer to be withdrawn.
 * @returns {Promise<void>} A promise that resolves when the offer is successfully withdrawn.
 * @throws Will throw an error if the withdrawal fails or if unable to reach the API.
 */
export async function withdrawOffer(offerId: number): Promise<void> {
  try {
    const user = await authService.getUser();
    const url = `${BASE_API_URL}${offerId}`;
    await axios.put(url, {}, {
      headers: {
        Authorization: authService.getAuthorizationHeaderValue(user),
        'Content-Type': 'application/json',
      },
    });

  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Creates a new offer.
 * @param {OfferPost} offer - The offer details required for creating a new offer.
 * @returns {Promise<Offer>} A promise that resolves to the newly created offer.
* @throws Will throw an error if the creation fails or if unable to reach the API.
 */
export async function createOffer(offer: OfferPost): Promise<Offer> {
  try {
    const user = await authService.getUser();
    const url = BASE_API_URL;
    const response: AxiosResponse<Offer> = await axios.post(url, offer, {
      headers: {
        Authorization: authService.getAuthorizationHeaderValue(user),
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Calculates the value coefficient and percentage for the given offer.
 * @param {OfferCodValueRatio} offer - The offer details required for the calculation.
 * @returns {Promise<CodValueResult>} A promise that resolves to an object containing both the value coefficient and percentage.
 * @throws Will throw an error if the calculation fails or if unable to reach the API.
 */
export async function getValueCoefficientAndPercentage(offer: OfferCodValueRatio): Promise<CodValueResult> {
  try {
    const user = await authService.getUser();
    const url = `${BASE_API_URL}calculate-valuecoefficient-and-percentage`;
    const response: AxiosResponse<CodValueResult> = await axios.post(url, offer, {
      headers: {
        Authorization: authService.getAuthorizationHeaderValue(user),
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * This function is responsible for fetching and returning the user information.
  * @returns {Promise<User | null>} - Returns a promise that resolves to a User object if the user is found; otherwise, resolves to null.
 */
export async function getUser(): Promise<User | null> {
  const authService = new AuthService();
  const user = await authService.getUser();
  if (user) {
    return user;
  } else {
    return null;
  }
}