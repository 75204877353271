/* eslint-disable */
import { CircularProgress, Typography, Divider, Grid, Button, CardActions, Container, Card, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import { useState, useEffect, useRef } from 'react';
import Offer from '../Models/Offer';
import OfferMarket from '../Models/OfferMarket'
import { getMarketAndInfo, getCompanyOffers, handleUnauthorizedError } from '../Services/OfferService';
import Header from './Header';
import MarketDetails from './MarketDetails'
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import OfferGridData from '../Models/OfferGridData';
import React from 'react';
import SnackbarMessage from "./SnackbarMessage";
import WithdrawOfferDialog from "./OfferWithdrawDialog";
import OfferFormDialog from "./OfferFormDialog";
import dayjs from 'dayjs';
import 'dayjs/locale/is';
import { MESSAGES } from '../Services/MessagesTexts';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { FaFish, FaShip } from 'react-icons/fa';
import { MdDateRange, MdPieChart } from 'react-icons/md';
import { alpha, styled } from '@mui/material/styles';
import StatusChip from './StatusChip';
import { FishYearCheckboxes, FishYearSelection } from './FishYearCheckboxes';
import { formatNumber, formatNumberFloat } from '../Services/FormatService';

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: '#f5f5f5',
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
          theme.palette.action.selectedOpacity +
          theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

function OfferHomePage() {

  // Use Icelandic locale
  dayjs.locale('is');
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  document.body.style.backgroundColor = isLargeScreen ? '#f5f5f5' : '#FFFFFF';

  const [offerGridData, setOfferGridData] = useState<OfferGridData[]>([]);
  const [loadingMarketAndFishYears, setLoadingMarketAndFishYears] = useState(true);
  const [loadingCompanyOffers, setLoadingCompanyOffers] = useState(false);
  const [openWithdrawDialog, setOpenWithdrawDialog] = useState(false);
  const [buttonProps, setButtonProps] = useState({ disabled: true });
  const [isError, setError] = useState(false);
  const [isFormDialogOpen, setOpenFormDialog] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState({} as OfferGridData);
  const [currentMarket, setCurrentMarket] = useState<OfferMarket>({} as OfferMarket);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [availableYears, setAvailableYears] = useState<string[]>([]);
  const [tonQuotaTypes, setTonQuotaTypes] = useState<number[]>([]);
  const initialSelectedYears = availableYears.reduce(
    (acc, year) => ({ ...acc, [year]: false }),
    { allFishYears: false }
  );
  const [selectedYears, setSelectedYears] = useState<Record<string, boolean>>(initialSelectedYears);
  const renderCount = useRef(0);
  const initialRender = useRef(true);

  useEffect(() => {
    const newSelectedYears = availableYears.reduce(
      (acc, year) => ({ ...acc, [year]: false }),
      {}
    );
    setSelectedYears(prevState => ({ ...prevState, ...newSelectedYears }));
  }, [availableYears]);


  const [snackbarData, setSnackbarData] = React.useState<{
    message: string;
    type: 'success' | 'error' | 'info' | 'warning';
  } | null>(null);

  useEffect(() => {
    const initializeData = async () => {
      await fetchMarketAndFishYears();
    };
    initializeData();
  }, []);


  const fetchMarketAndFishYears = async (): Promise<void> => {
    try {
      let { currentMarket, fishYears, tonQuotaTypes: tonQuotaTypes } = await getMarketAndInfo();
      const isMarketClosed = currentMarket.id === null || currentMarket.id === undefined || currentMarket.id === 0;
      currentMarket.isClosed = isMarketClosed;
      setAvailableYears(fishYears);
      setCurrentMarket(currentMarket);
      setTonQuotaTypes(tonQuotaTypes);
    } catch (error) {
      handleUnauthorizedError(error);
      setSnackbarOpen(true);
      setSnackbarData({ message: MESSAGES.FETCH_ERROR, type: 'error' });
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      // If it's the first render, just mark it as done and skip the rest of the effect.
      initialRender.current = false;
      return;
    }
    // If current market is closed disable the button.
    if (currentMarket && !currentMarket.isClosed) {
      setButtonProps({ disabled: false });
    } else {
      setButtonProps({ disabled: true });
    }
    fetchCompanyOffers([], false);
  }, [currentMarket]);

  /**
   * Fetches the company offers based on the selected years and `allFishYears` status. 
   * If no years are selected, and the `allFishYears` status is false, and the current market is empty, it fetches no offers.
   * The fetched offers are then sorted by date in descending order. 
   * These offers are transformed into a format suitable for the Offer Grid and then set to state.
   * If there's any error during the fetch, an error snackbar is shown.
   * 
   * @param selectedYears - Array of selected years.
   * @param allFishYears - Boolean indicating if all fish years are selected.
   * @returns Promise<void>
   */
  const fetchCompanyOffers = async (
    selectedYears: string[],
    allFishYears: boolean,
  ): Promise<void> => {
    setLoadingCompanyOffers(true);
    try {
      const emptyOfferList = selectedYears.length === 0 && !allFishYears && currentMarket.isClosed;
      let offers: Offer[] = [];
      if (!emptyOfferList) {
        offers = await getCompanyOffers(allFishYears, selectedYears);
      }
      const sortedOffers = offers.sort((a: Offer, b: Offer) => {
        return dayjs(b.date).valueOf() - dayjs(a.date).valueOf();
      });
      setOfferGridData(
        sortedOffers.map((offer: Offer) => ({
          id: offer.id,
          shipName: offer.ship.name,
          shipNumber: offer.ship.number,
          offerQuotaName: offer.offerQuota.name,
          offerQuotaQuantity: offer.offerQuota.quantity,
          paymentQuotaName: offer.paymentQuota.name,
          paymentQuotaQuantity: offer.paymentQuota.quantity,
          date: dayjs(offer.date).format('DD.MM.YYYY HH:mm'),
          status: offer.status,
          codValueRatio: offer.codValueRatio,
          valueCoefficient: offer.valueCoefficient,
          statusDescription: offer.statusDescription,
          isOpenMarket: offer.isOpenMarket,
          offerQuotaType: offer.offerQuota.quotaType,
          paymentQuotaType: offer.paymentQuota.quotaType,
        }))
      );
    } catch (error) {
      handleUnauthorizedError(error);
      setSnackbarOpen(true);
      setSnackbarData({ message: MESSAGES.FETCH_ERROR, type: 'error' });
    } finally {
      setLoadingCompanyOffers(false);
      setLoadingMarketAndFishYears(false);
    }
  };


  /**
   * Handles the actions after an offer has been withdrawn. 
   * It first displays a success snackbar notifying the user about the withdrawal of the offer. 
   * Next, it reconstructs the list of selected years (excluding the 'allFishYears' checkbox) 
   * and then fetches company offers again to reflect any changes due to the withdrawal. 
   * In case there's an error while fetching the offers, it sets the error state 
   * and displays an error snackbar.
   */
  const handleOfferWithdrawn = async () => {
    setSnackbarOpen(true);
    setSnackbarData({ message: MESSAGES.OFFER_WITHDRAWN, type: 'success' });

    // Extract the selected years and the special checkboxes' state
    const selectedYearList: string[] = Object.keys(selectedYears).filter(year => selectedYears[year] && year !== 'allFishYears');

    try {
      await fetchCompanyOffers(selectedYearList, selectedYears.allFishYearsState);
    } catch (error) {
      setError(true);
      setSnackbarOpen(true);
      setSnackbarData({ message: MESSAGES.FETCH_ERROR, type: 'error' });
    }
  };

  /**
   * Handles the actions after the create offer dialog has been closed. 
   * If an offer has been successfully submitted, it displays a success snackbar 
   * notifying the user about the new offer. 
   * Then, it reconstructs the list of selected years (excluding the 'allFishYears' checkbox) 
   * and fetches company offers again to reflect the addition of the new offer.
   * 
   * @param offerSubmitted - Boolean indicating if an offer was successfully submitted from the dialog.
   */
  const handleCloseCreateFormDialog = (offerSubmitted: boolean) => {
    setOpenFormDialog(false);
    if (offerSubmitted) {
      setSnackbarData({ message: 'Tilboð skráð', type: 'success' });
      setSnackbarOpen(true);
      const selectedYearList: string[] = Object.keys(selectedYears).filter(year => selectedYears[year] && year !== 'allFishYears');
      fetchCompanyOffers(selectedYearList, selectedYears.allFishYears);
    }
  };

  const handleCloseWithdrawDialog = (offerWithdrawn: boolean) => {
    setOpenWithdrawDialog(false);
    if (offerWithdrawn) {
      handleOfferWithdrawn();
    }
  };

  function shouldDisplayWithdrawButton(offer: OfferGridData): boolean {
    return offer.status !== 0 && offer.isOpenMarket;
  }

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleOpenFormDialog = () => {
    setOpenFormDialog(true);
  };

  /**
   * Watches for changes in the `selectedYears` state which represents the checkboxes' selection.
   * The function first maintains a count of renders. For the initial three renders, it skips 
   * the fetching of company offers to avoid unnecessary data fetching.
   * 
   * After the initial renders, it reconstructs the list of selected years (excluding the 'allFishYears' checkbox).
   * Then, it fetches the company offers to update the offer grid data based on the updated selection.
   * 
   * This ensures the displayed offers in the grid always match the user's checkbox selection.
   */
  useEffect(() => {
    // Increase the render count
    renderCount.current += 1;

    if (renderCount.current <= 3) {
      // Skip calling fetchCompanyOffers for the first three renders
      return;
    }
    const selectedYearList: string[] = Object.keys(selectedYears).filter(year => selectedYears[year] && year !== 'allFishYears');

    const selection: FishYearSelection = {
      selectedYears: selectedYearList,
      allFishYears: selectedYears.allFishYears
    };

    // Call fetchCompanyOffers with the selected parameters
    fetchCompanyOffers(selection.selectedYears, selection.allFishYears);

  }, [selectedYears]);

  const columns: GridColDef[] = [
    {
      field: 'action',
      headerName: 'Afturkalla',
      width: 100,
      renderCell: (params) => {
        if (!shouldDisplayWithdrawButton(params.row)) {
          return null;
        }

        return (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setSelectedOffer(params.row as OfferGridData);
              setOpenWithdrawDialog(true);
            }}
            style={{
              borderRadius: '4px',
              textTransform: 'none',
              minWidth: 'auto',
              padding: '6px 10px',
            }}
          >
            Afturkalla
          </Button>
        );
      },
    },
    {
      field: 'shipName',
      headerName: 'Skip',
      width: 130,
      renderCell: (params) => {
        return (
          <>
            {params.value}
            <br />
            Númer: {params.row.shipNumber}
          </>
        );
      }
    },
    { field: 'date', headerName: 'Dagsetning', width: 140 },
    {
      field: 'offerQuotaName',
      headerName: 'Óska eftir fisktegund',
      width: 180,
      renderCell: (params) => {
        return (
          <>
            {params.value}
            <br />
            Magn: {formatNumber(params.row.offerQuotaQuantity)} {tonQuotaTypes.includes(params.row.offerQuotaType) ? 'tonn' : 'kg'}
          </>
        );
      }
    },
    {
      field: 'paymentQuotaName',
      headerName: 'Greiða með fisktegund',
      width: 180,
      renderCell: (params) => {
        return (
          <>
            {params.value}
            <br />
            Magn: {formatNumber(params.row.paymentQuotaQuantity)} {tonQuotaTypes.includes(params.row.paymentQuotaType) ? 'tonn' : 'kg'}
          </>
        );
      }
    },
    {
      field: 'codValueRatio',
      headerName: 'Þorskígildihlutfall',
      width: 130,
      renderCell: (params) => {
        return `${formatNumber(params.value)}%`;
      }
    },
    {
      field: 'valueCoefficient',
      headerName: 'Virðisstuðull',
      width: 100,
      renderCell: (params) => {
        return `${formatNumberFloat(params.value)}`;
      }
    },
    {
      field: 'status',
      headerName: 'Staða',
      width: 170,
      renderCell: (params) => {
        const statusDescription = params.row.statusDescription ? params.row.statusDescription : (params.row.status === 1 ? "MÓTTEKIÐ" : "AFTURKALLAÐ");
        return <StatusChip status={statusDescription} />;
      },
    },
  ];

  const renderCard = (offer: OfferGridData) => (
    <Grid key={offer.id}>
      <Card style={{ width: '100%', position: 'relative' }}>
        {shouldDisplayWithdrawButton(offer) && (
          <CardActions style={{ position: 'absolute', bottom: 0, right: 0, zIndex: 1 }}>
            <Button style={{ textTransform: 'none' }}
              variant="contained"
              color="error"
              onClick={() => {
                setSelectedOffer(offer);
                setOpenWithdrawDialog(true);
              }}
            >
              Afturkalla
            </Button>
          </CardActions>
        )}
        <CardContent>
          <Typography variant="h5" style={{ marginBottom: '8px' }}><FaShip /> {offer.shipName + ' (' + offer.shipNumber + ')'}</Typography>
          <Divider style={{ marginBottom: '16px' }} />
          <Typography variant="body2"><MdDateRange /> Dagsetning: {offer.date}</Typography>
          <Typography variant="body2">
          <FaFish /> Óska eftir: {offer.offerQuotaName} (Magn: {offer.offerQuotaQuantity} {tonQuotaTypes.includes(offer.offerQuotaType) ? 'tonn' : 'kg'})
          </Typography>
          <Typography variant="body2">
          <FaFish /> Greiða með: {offer.paymentQuotaName} (Magn: {offer.paymentQuotaQuantity} {tonQuotaTypes.includes(offer.paymentQuotaType) ? 'tonn' : 'kg'})
          </Typography>
          <Typography variant="body2"><MdPieChart /> Þorskígildihlutfall: {offer.codValueRatio}%</Typography>
          <Typography style={{ marginBottom: '2px' }} variant="body2"><MdPieChart /> Virðisstuðull: {offer.valueCoefficient}</Typography>
          <StatusChip status={offer.statusDescription ? offer.statusDescription : (offer.status === 1 ? "Móttekið" : "Afturkallað")} />
        </CardContent>
      </Card>
    </Grid>
  );

  return (
    <div>
      {selectedOffer && (<WithdrawOfferDialog open={openWithdrawDialog} onClose={handleCloseWithdrawDialog} offer={selectedOffer} tonQuotaTypes={tonQuotaTypes}/>)}
      <Header />
      {snackbarData && (
        <SnackbarMessage
          message={snackbarData.message}
          type={snackbarData.type}
          open={snackbarOpen}
          handleClose={closeSnackbar}
        />
      )}
      {
        !isError && (
          <Container style={{ flexGrow: 1, backgroundColor: '#FFFFFF', display: 'flex', flexDirection: 'column', height: 'calc(100vh - 65px)' }}>
            {loadingMarketAndFishYears ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 3 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 3 }}>
                  {loadingMarketAndFishYears ? (
                    <CircularProgress />
                  ) : (
                    <MarketDetails offerMarket={currentMarket} />
                  )}
                </Box>
                {offerGridData && (
                  <Grid container spacing={4} sx={{ my: 0 }}>
                    <Grid item container justifyContent="center" >
                      <Button
                        {...buttonProps}
                        variant="contained"
                        color="primary"
                        style={{ width: '35em', height: '100%' }}
                        onClick={handleOpenFormDialog}
                        disabled={loadingMarketAndFishYears || buttonProps.disabled}
                      >
                        Skrá Nýtt tilboð
                      </Button>
                      {!buttonProps.disabled && <OfferFormDialog open={isFormDialogOpen} onClose={handleCloseCreateFormDialog} tonQuotaTypes={tonQuotaTypes} />}
                    </Grid>
                    <Grid item xs={12}>
                      <FishYearCheckboxes
                        years={availableYears}
                        selectedYearsState={{ selectedYears, setSelectedYears }}
                        isOpenMarketEmpty={currentMarket.isClosed}
                      />
                    </Grid>
                  </Grid>
                )}
                {isLargeScreen ? (
                  offerGridData && (
                    <StripedDataGrid
                      rows={offerGridData}
                      columns={columns}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                      }
                      loading={loadingCompanyOffers}
                      localeText={{
                        noRowsLabel: MESSAGES.NO_OFFERS_IN_MARKETPLACE,
                        MuiTablePagination: {
                          labelRowsPerPage: MESSAGES.LINES_PER_PAGE,
                        },
                      }}
                    />
                  )
                ) : (
                  <div>
                    {loadingCompanyOffers ? (
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                      }}>
                        <CircularProgress />
                      </div>
                    ) : offerGridData.length > 0 ? (
                      <Grid container spacing={2}>
                        {offerGridData.map(offer => (
                          <Grid item xs={12} md={6} key={offer.id}>
                            {renderCard(offer)}
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <div style={{
                        padding: '20px',
                        textAlign: 'center',
                        color: 'gray',
                        fontStyle: 'italic'
                      }}>
                        {MESSAGES.NO_OFFERS_IN_MARKETPLACE}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </Container>
        )
      }
    </div>
  );
}

export default OfferHomePage;
