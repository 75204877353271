import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useState, useEffect, ChangeEvent } from 'react';
import { getOfferMarketDetails, createOffer, getValueCoefficientAndPercentage, handleUnauthorizedError } from '../Services/OfferService';
import OfferDetails from '../Models/OfferDetails';
import CalculateCodValueInput from '../Models/CalculateCodValueInput'
import CodValueResult from '../Models/CodValueResult'
import OfferPost from '../Models/OfferPost'
import { Dialog, DialogTitle, DialogContent, Divider, FormControlLabel, Checkbox, Typography, Box, FormControl, InputLabel, Select, MenuItem, FormHelperText, OutlinedInput, InputAdornment, DialogActions, Button, CircularProgress } from '@mui/material';
import SnackbarMessage from "./SnackbarMessage";
import React from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import { MESSAGES } from '../Services/MessagesTexts';
import { FaFish } from 'react-icons/fa';
import { FaShip } from 'react-icons/fa';
import { MdPieChart } from 'react-icons/md';
import { AiOutlineNumber } from 'react-icons/ai';
import debounce from 'lodash.debounce';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Ship from '../Models/Ship';
import { formatNumber, formatNumberFloat } from '../Services/FormatService';

type OfferFormDialogProps = {
    open: boolean;
    onClose: (offerSubmitted: boolean) => void;
    tonQuotaTypes: number[];
};

const DEBOUNCE_TIME = 300;

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function OfferFormDialog({ open, onClose, tonQuotaTypes: tonQuotaTypes }: OfferFormDialogProps) {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
    const offerDetailsInit = { id: 0, offerMarketNumber: 0, validFrom: new Date(), validTo: new Date(), minimumOffer: 0, offerQuota: [], paymentQuota: [], ships: [], allocationFee: 0 };
    const [offerDetails, setOfferDetails] = useState<OfferDetails>(offerDetailsInit);
    const offerFormInit: OfferPost = { shipNumber: undefined, offerQuotaType: undefined, offerQuantity: undefined, paymentQuotaType: 1, paymentQuantity: undefined };
    const [formData, setFormData] = useState<OfferPost>(offerFormInit);
    const [percentageOfCodValueOffered, setPercentageOfCodValueOffered] = useState<number | null>(null);
    const [valueCoefficient, setValueCoefficient] = useState<number | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [shipNumberError, setShipNumberError] = useState({ isError: false, message: "", hasNoShip: false });
    const [offerQuotaTypeError, setOfferQuotaTypeError] = useState({ isError: false, message: "" });
    const [offerQuantityError, setOfferQuantityError] = useState({ isError: false, message: "" });
    const [paymentQuotaTypeError, setPaymentQuotaTypeError] = useState({ isError: false, message: "" });
    const [paymentQuantityError, setPaymentQuantityError] = useState({ isError: false, message: "" });
    const [valueCoefficientError, setValueCoefficientError] = useState({ isError: false, message: "" });
    const [percentageOfCodValueOfferedError, setPercentageOfCodValueOfferedError] = useState({ isError: false, message: "" });
    const [isFetchValueCoefficientAndPercentage, setIsFetchValueCoefficientAndPercentage] = useState(false);
    const [rawOfferQuantity, setRawOfferQuantity] = useState<string>("");
    const [rawPaymentQuantity, setRawPaymentQuantity] = useState<string>("");
    const [isChecked, setIsChecked] = useState(false);

    const [snackbarData, setSnackbarData] = React.useState<{
        message: string;
        type: 'success' | 'error' | 'info' | 'warning';
    } | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const averagePrice = offerDetails.paymentQuota.find(item => item.quotaType === formData.paymentQuotaType)?.averagePrice || 'N/A';
    const formattedAveragePrice = averagePrice !== 'N/A' ? formatNumberFloat(averagePrice.toString()) : 'N/A';

    /**
     * Determines if the ship array is empty, signifying no ships.
     * 
     * @param {Ship[]} ships - Array of ship objects to check.
     * @returns {boolean} True if no ships are present, false otherwise.
     */
    const hasNoShip = (ships: Ship[]): boolean => {
        return ships.length === 0;
    };

    useEffect(() => {
        fetchOfferDetails();
    }, []);

    /**
     * Fetches and processes offer details for the market form.
     * It sets form data for a single ship and handles errors or the absence of ships.
     */
    const fetchOfferDetails = async () => {
        setIsLoading(true);
        try {
            const data: OfferDetails = await getOfferMarketDetails();
            setOfferDetails(data);
            const hasSingleShip = data.ships.length === 1;
            if (hasSingleShip) {
                setFormData(prevState => ({
                    ...prevState,
                    shipNumber: data.ships[0].number,
                    offerMarketNumber: data.offerMarketNumber
                }));
            } else if (hasNoShip(data.ships)) {
                setShipNumberError({ isError: true, message: MESSAGES.SHIP_REGISTRATION_REQUIRED, hasNoShip: true });
            }
            setIsLoading(false);
        } catch (error) {
            handleUnauthorizedError(error);
            setIsLoading(false);
            setSnackbarOpen(true);
            setSnackbarData({ message: MESSAGES.FETCH_ERROR, type: 'error' });
            console.error(error);
        }
    }

    /**
     * Triggers the fetchCodValueRatio() function to calculate the cod value ratio.
     * Invoked when there is a change in formData. 
     */
    useEffect(() => {
        if (isFormDataComplete(formData)) {
            const hasError = handleFormValidation();
            if (!hasError) {
                debouncedFetchValueCoefficientAndPercentage();

            } else {
                setPercentageOfCodValueOffered(null);
                setValueCoefficient(null);
            }
        } else {
            setPercentageOfCodValueOffered(null);
            setValueCoefficient(null);
        }
        return () => {
            debouncedFetchValueCoefficientAndPercentage.cancel();
        };
    }, [formData]);

    const validateShipNumber = (localFormData: OfferPost) => {
        if (hasNoShip(offerDetails.ships)) {
            if (!localFormData.shipNumber) {
                setShipNumberError({ isError: true, message: MESSAGES.SELECT_SHIP_TYPE, hasNoShip: false });
                return true;
            }
        }
        setShipNumberError({ isError: false, message: "", hasNoShip: false });
        return false;
    };

    const validateOfferQuotaType = (localFormData: OfferPost) => {
        if (!localFormData.offerQuotaType) {
            setOfferQuotaTypeError({ isError: true, message: MESSAGES.SELECT_FISH_TYPE_FOR_PURCHASE });
            return true;
        }
        setOfferQuotaTypeError({ isError: false, message: "" });
        return false;
    };

    /**
     * `validateOfferQuantity` is a function that ensures the offer quantity entered in a form meets specific criteria.
     * It takes in `localFormData`, an object containing the form's current state, and a `skipEmptyValueCheck` boolean
     * that determines whether to bypass validation for empty values. This function is used to validate the offerQuantity field
     * within the form, checking it against quota details from `offerDetails.offerQuota`.
     *
     * When `skipEmptyValueCheck` is `true`, the function will not set an error for an empty quantity, which is the case
     * when a quota type is changed and the quantity field is still empty. This avoids presenting an error message to the user
     * prematurely. If `skipEmptyValueCheck` is `false`, and the quantity is empty or exceeds the maximum allowed for the selected
     * quota, it sets an error with an appropriate message sourced from `MESSAGES`.
     *
     * The function returns `true` if an error is set, indicating that the quantity is either empty or 0 when it shouldn't be or exceeds
     * the maximum allowed quantity. Otherwise, it returns `false`, meaning the quantity is valid.
     */
    const validateOfferQuantity = (localFormData: OfferPost, skipEmptyValueCheck: boolean) => {
        // If the quota type is empty, reset the error state and return false.
        if (!localFormData.offerQuotaType) {
            setOfferQuantityError({ isError: false, message: "" });
            return false;
        }
        const foundQuota = offerDetails.offerQuota.find(quota => quota.quotaType === localFormData.offerQuotaType);
        if (localFormData.offerQuantity == 0 && localFormData.offerQuantity.toString() !== "") {
            let message = MESSAGES.QUANTITY_OVER + "."
            if (formData.offerQuotaType) {
                const isTonQuotaType = tonQuotaTypes.some(tqt => tqt === formData.offerQuotaType);
                if (isTonQuotaType) {
                    message = MESSAGES.QUANTITY_OVER + " tonn."
                } else {
                    message = MESSAGES.QUANTITY_OVER + " kg."
                }
            }
            setOfferQuantityError({ isError: true, message: message });
            return true;
        }
        else if (!localFormData.offerQuantity) {
            if (skipEmptyValueCheck) {
                setOfferQuantityError({ isError: false, message: "" });
            } else if (foundQuota) {
                const maxAllowedQuotaQuantity = foundQuota.quantity;
                setOfferQuantityError({
                    isError: true,
                    message: MESSAGES.SELECT_QUANTITY_FOR_PURCHASE +
                        MESSAGES.MAX_QUOTA(
                            maxAllowedQuotaQuantity,
                            formData.offerQuotaType && tonQuotaTypes.some(tqt => tqt === formData.offerQuotaType) ? "tonn" : "kg"
                        )
                });
            } else {
                setOfferQuantityError({ isError: true, message: MESSAGES.SELECT_QUANTITY_FOR_PURCHASE });
            }
            return true;
        } else {
            if (!foundQuota) {
                setOfferQuantityError({ isError: true, message: MESSAGES.QUANTITY_CALCULATION_ERROR });
                return true;
            } else {
                const maxAllowedQuotaQuantity = foundQuota.quantity;
                if (localFormData.offerQuantity > maxAllowedQuotaQuantity) {
                    setOfferQuantityError({
                        isError: true,
                        message: MESSAGES.MAX_QUOTA(
                            maxAllowedQuotaQuantity,
                            formData.offerQuotaType && tonQuotaTypes.some(tqt => tqt === formData.offerQuotaType) ? "tonn" : "kg"
                        )
                    });
                    return true;
                }
            }
        }
        setOfferQuantityError({ isError: false, message: "" });
        return false;
    };

    const validatePaymentQuotaType = (localFormData: OfferPost) => {
        if (!localFormData.paymentQuotaType) {
            setPaymentQuotaTypeError({ isError: true, message: MESSAGES.SELECT_FISH_TYPE_FOR_PAYMENT });
            return true;
        }
        setPaymentQuotaTypeError({ isError: false, message: "" });
        return false;
    };

    const validatePaymentQuantity = (localFormData: OfferPost) => {
        if (localFormData.paymentQuantity == 0 && localFormData.paymentQuantity.toString() !== "") {
            setPaymentQuantityError({ isError: true, message: MESSAGES.QUANTITY_OVER + " kg." });
            return true;
        } else if (!localFormData.paymentQuantity) {
            setPaymentQuantityError({ isError: true, message: MESSAGES.SELECT_QUANTITY_FOR_PAYMENT });
        }
        setPaymentQuantityError({ isError: false, message: "" });
        return false;
    };


    const handleFormValidation = () => {
        let hasError = false;
        hasError = validateShipNumber(formData) || hasError;
        hasError = validateOfferQuotaType(formData) || hasError;
        hasError = validateOfferQuantity(formData, false) || hasError;
        hasError = validatePaymentQuotaType(formData) || hasError;
        hasError = validatePaymentQuantity(formData) || hasError;

        if (isFormDataComplete(formData)) {
            if (validateCodValueRatio(percentageOfCodValueOffered)) {
                hasError = true;
            }
        }

        return hasError;
    }

    /**
     * Checks if all the necessary formData fields are filled.
     *
     * @param {OfferPost} data - The form data object to validate.
     * @returns {boolean} - Returns `true` if all necessary fields are filled, otherwise `false`.
     */
    const isFormDataComplete = (data: OfferPost): boolean => {
        if (data.shipNumber && data.offerQuotaType && data.offerQuantity && data.paymentQuotaType && data.paymentQuantity) {
            return true;
        }
        else {
            return false;
        }
    };

    /**
     * Validates the provided cod value ratio against the minimum offer requirement.
     * Updates the codValueRatio error state based on the validation result.
     *
     * @param {number} codValueRatio - The cod value ratio to validate.
     * @returns {boolean} - Returns `true` if there is an error (i.e., if the provided ratio is less than the minimum offer), otherwise `false`.
     */
    const validateCodValueRatio = (codValueRatio: number | null): boolean => {
        if (codValueRatio == null) {
            return false;
        }
        if (codValueRatio < offerDetails.minimumOffer) {
            setPercentageOfCodValueOfferedError({ isError: true, message: MESSAGES.LOW_COD_VALUE_RATIO_ERROR });
            return true;
        } else {
            setPercentageOfCodValueOfferedError({ isError: false, message: "" });
            return false;
        }
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const hasError = handleFormValidation();
        if (hasError) {
            setSnackbarOpen(true);
            setSnackbarData({ message: MESSAGES.REGISTRATION_ERROR_CORRECTION, type: 'error' });
            return;
        }

        const offer = {
            offerMarketNumber: offerDetails.offerMarketNumber,
            shipNumber: formData.shipNumber,
            offerQuotaType: formData.offerQuotaType,
            offerQuantity: formData.offerQuantity,
            paymentQuotaType: formData.paymentQuotaType,
            paymentQuantity: formData.paymentQuantity
        };

        try {
            setIsSubmitting(true);
            await createOffer(offer);
            onClose(true);
            setIsSubmitting(false);
            // Reset the form.
            setFormData(offerFormInit);
            setRawOfferQuantity("");
            setRawPaymentQuantity("");
            setIsChecked(false);
        } catch (error) {
            handleUnauthorizedError(error);
            setIsSubmitting(false);
            setSnackbarOpen(true);
            setSnackbarData({ message: MESSAGES.OFFER_REGISTRATION_ERROR, type: 'error' });
        }

    };

    const fetchValueCoefficientAndPercentage = async () => {
        setIsFetchValueCoefficientAndPercentage(true);
        try {
            const offer: CalculateCodValueInput = {
                offerMarketNumber: offerDetails.offerMarketNumber,
                offerQuotaType: formData.offerQuotaType,
                offerQuantity: formData.offerQuantity,
                paymentQuotaType: formData.paymentQuotaType,
                paymentQuantity: formData.paymentQuantity
            };

            const codValueResult: CodValueResult = await getValueCoefficientAndPercentage(offer);
            setPercentageOfCodValueOffered(codValueResult.percentageOfCodValueOffered);
            setValueCoefficient(codValueResult.valueCoefficient);
            validateCodValueRatio(codValueResult.percentageOfCodValueOffered);
            setValueCoefficientError({ isError: false, message: "" })
        } catch (error) {
            handleUnauthorizedError(error);
            setSnackbarOpen(true);
            setSnackbarData({ message: MESSAGES.CALCULATION_ERROR, type: 'error' });
            setPercentageOfCodValueOffered(null);
            setValueCoefficient(null);
            setPercentageOfCodValueOfferedError({ isError: true, message: MESSAGES.COD_RATIO_CALCULATION_ERROR });
            setValueCoefficientError({ isError: true, message: MESSAGES.VALUE_COEFFICIENT_CALCULATION_ERROR });
        }
        setIsFetchValueCoefficientAndPercentage(false);
    };

    /**
     * `debouncedFetchValueCoefficientAndPercentage` is a debounced version of the `fetchValueCoefficientAndPercentage` function,
     * ensuring that it's invoked only after a specified delay. This technique limits the rate at which the function is called.
     * It is particularly useful for handling events that fire frequently and need to fetch data, like on user input or window resizing.
     * The `DEBOUNCE_TIME` constant defines the waiting period before the actual `fetchValueCoefficientAndPercentage` function is executed.
     */
    const debouncedFetchValueCoefficientAndPercentage = debounce(fetchValueCoefficientAndPercentage, DEBOUNCE_TIME);

    /**
     * Handles changes to the select fields.
     * @param event 
     */
    const handleSelectChange = (event: SelectChangeEvent) => {
        const { name, value } = event.target;
        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                [name]: value
            };

            switch (name) {
                case 'shipNumber':
                    validateShipNumber(updatedFormData);
                    break;
                case 'offerQuotaType':
                    validateOfferQuotaType(updatedFormData);
                    validateOfferQuantity(updatedFormData, true);
                    break;
                case 'paymentQuotaType':
                    validatePaymentQuotaType(updatedFormData);
                    break;
            }

            return updatedFormData;
        });
    };

    /**
     * Handles changes to the input fields.
     * @param event
     */
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        const onlyDigitsValue = value.replace(/\D/g, ""); // Remove all non-digit characters

        if (name === 'offerQuantity') {
            setRawOfferQuantity(onlyDigitsValue);
        } else if (name === 'paymentQuantity') {
            setRawPaymentQuantity(onlyDigitsValue);
        }

        setFormData(prevFormData => {
            const updatedFormData = {
                ...prevFormData,
                [name]: onlyDigitsValue  // Store only the digits in the state for both fields
            };
            switch (name) {
                case 'offerQuantity':
                    validateOfferQuantity(updatedFormData, false);
                    break;
                case 'paymentQuantity':
                    validatePaymentQuantity(updatedFormData);
                    break;
            }
            return updatedFormData;
        });
    };

    const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
    };

    return (
        <div>
            {snackbarData && (
                <SnackbarMessage
                    message={snackbarData.message}
                    type={snackbarData.type}
                    open={snackbarOpen}
                    handleClose={closeSnackbar}
                />
            )}
            <Dialog
                open={open}
                onClose={() => onClose(false)}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{
                    cursor: 'move',
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    color: '#42a5f5',
                }} id="draggable-dialog-title">
                    Skrá nýtt tilboð
                </DialogTitle>
                <DialogContent>
                    {(isSubmitting || isLoading) && (
                        <div style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 1000,
                            pointerEvents: 'none',
                        }}>
                            <CircularProgress />
                        </div>
                    )}
                    <Divider style={{ marginBottom: '16px' }} />
                    <Typography variant="body1" style={{ lineHeight: '1.6', marginBottom: '16px' }}>
                        Skilyrði þess að tilboð sé gilt, er að nægjanlegt aflamark í þeirri tegund sem láta á í skiptum sé á skipinu, og nægt magn af þeirri tegund sem óskað sé eftir sé í boði.
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '16px' }}>Gjald fyrir úthlutun aflaheimilda á tilboðsmarkaði: {offerDetails.allocationFee} krónur.</Typography>
                    <Typography variant="body1" style={{ marginBottom: '16px' }}>
                        {offerDetails.minimumOffer === 0 ? MESSAGES.NO_MINIMUM_OFFER : `${MESSAGES.MINIMUM_OFFER} ${offerDetails.minimumOffer}%`}
                    </Typography>
                    <Divider style={{ marginBottom: '16px' }} />
                    <form onSubmit={handleSubmit}>
                        <Box marginBottom={2}>
                            <FormControl fullWidth disabled={isSubmitting || shipNumberError.hasNoShip}>
                                <InputLabel htmlFor="outlined-required">
                                    Skip
                                </InputLabel>
                                <Select
                                    id="outlined-required"
                                    label="Skip"
                                    name="shipNumber"
                                    value={formData.shipNumber !== undefined ? String(formData.shipNumber) : ''}
                                    onChange={handleSelectChange}
                                    error={shipNumberError.isError}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <FaShip />
                                        </InputAdornment>
                                    }
                                >
                                    {offerDetails.ships.map((option) => (
                                        <MenuItem
                                            key={option.name + " (" + option.number + ")"}
                                            value={option.number}
                                        >
                                            {option.name + " (" + option.number + ")"}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={shipNumberError.hasNoShip ? { color: 'red', fontWeight: 'bold' } : {}}>
                                    {shipNumberError.isError === false ? MESSAGES.SELECT_SHIP_TYPE : shipNumberError.message}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box display="flex"
                            flexDirection={isMobile ? "row" : "column"}
                            justifyContent="space-between"
                            marginBottom={2}
                            gap={isMobile ? 0 : 2} >
                            <FormControl sx={{ m: isMobile ? 1 : 0, width: isMobile ? '32ch' : '100%' }} variant="outlined" disabled={isSubmitting || shipNumberError.hasNoShip}>
                                <InputLabel htmlFor="outlined-required">Fisktegund</InputLabel>
                                <Select
                                    id="outlined-required"
                                    label="Fisktegund"
                                    name="offerQuotaType"
                                    value={formData.offerQuotaType !== undefined ? String(formData.offerQuotaType) : ''}
                                    onChange={handleSelectChange}
                                    error={offerQuotaTypeError.isError}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <FaFish />
                                        </InputAdornment>
                                    }
                                >
                                    {offerDetails.offerQuota.map((option) => (
                                        <MenuItem key={option.name} value={option.quotaType}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={offerQuotaTypeError.isError ? { color: 'red', fontWeight: 'bold' } : {}}>
                                    {offerQuotaTypeError.isError === false
                                        ? MESSAGES.WISH_FOR_FISH_TYPE_FOR_PURCHASE
                                        : offerQuotaTypeError.message}
                                </FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: isMobile ? 1 : 0, width: isMobile ? '32ch' : '100%' }} variant="outlined" disabled={isSubmitting || shipNumberError.hasNoShip}>
                                <InputLabel htmlFor="outlined-required">Magn</InputLabel>
                                <OutlinedInput
                                    id="outlined-required"
                                    label="Magn"
                                    value={formatNumber(rawOfferQuantity)}
                                    onChange={handleInputChange}
                                    error={offerQuantityError.isError}
                                    type="text"
                                    inputProps={{
                                        name: "offerQuantity",
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <AiOutlineNumber />
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {formData.offerQuotaType && tonQuotaTypes.some(tqt => tqt === formData.offerQuotaType) ? "tonn" : "kg"}
                                        </InputAdornment>
                                    }
                                    onKeyPress={(event) => {
                                        if (event.key === ".") {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                <FormHelperText style={offerQuantityError.isError ? { color: 'red', fontWeight: 'bold' } : {}}>
                                    {offerQuantityError.isError
                                        ? offerQuantityError.message
                                        : (() => {
                                            const foundQuota = offerDetails.offerQuota.find(quota => quota.quotaType === formData.offerQuotaType);
                                            return foundQuota
                                                ? <>
                                                    {MESSAGES.QUANTITY_FOR_PURCHASE}
                                                    <span style={{ fontWeight: 'bold', color: 'black' }}>
                                                        {MESSAGES.MAX_QUOTA(
                                                            foundQuota.quantity,
                                                            formData.offerQuotaType && tonQuotaTypes.some(tqt => tqt === formData.offerQuotaType) ? "tonn" : "kg"
                                                        )}
                                                    </span>
                                                </>
                                                : MESSAGES.QUANTITY_FOR_PURCHASE;
                                        })()
                                    }
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box display="flex"
                            flexDirection={isMobile ? "row" : "column"}
                            justifyContent="space-between"
                            marginBottom={2}
                            gap={isMobile ? 0 : 2} >
                            <FormControl sx={{ m: isMobile ? 1 : 0, width: isMobile ? '32ch' : '100%' }} variant="outlined" disabled={isSubmitting || shipNumberError.hasNoShip}>
                                <InputLabel htmlFor="outlined-required">Fisktegund</InputLabel>
                                <Select
                                    id="outlined-required"
                                    label="Fisktegund"
                                    name="paymentQuotaType"
                                    value={formData.paymentQuotaType !== undefined ? String(formData.paymentQuotaType) : ''}
                                    onChange={handleSelectChange}
                                    error={paymentQuotaTypeError.isError}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <FaFish />
                                        </InputAdornment>
                                    }
                                >
                                    {offerDetails.paymentQuota.map((option) => (
                                        <MenuItem key={option.name} value={option.quotaType}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={paymentQuotaTypeError.isError ? { color: 'red', fontWeight: 'bold' } : {}}>
                                    {paymentQuotaTypeError.isError === false
                                        ? <>
                                            {MESSAGES.FISH_TYPE_FOR_PAYMENT}<br />
                                            <strong style={{ fontWeight: 'bold', color: 'black' }}> Meðalverð aflamarks: {formattedAveragePrice} kr/kg.                                            </strong>
                                        </>
                                        : paymentQuotaTypeError.message
                                    }
                                </FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: isMobile ? 1 : 0, width: isMobile ? '32ch' : '100%' }} variant="outlined" disabled={isSubmitting || shipNumberError.hasNoShip}>
                                <InputLabel htmlFor="outlined-required">Magn</InputLabel>
                                <OutlinedInput
                                    id="outlined-required"
                                    label="Magn"
                                    value={formatNumber(rawPaymentQuantity)}
                                    onChange={handleInputChange}
                                    error={paymentQuantityError.isError}
                                    type="text"
                                    inputProps={{
                                        name: "paymentQuantity",
                                    }}
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <AiOutlineNumber />
                                        </InputAdornment>
                                    }
                                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                                    onKeyPress={(event) => {
                                        if (event.key === ".") {
                                            event.preventDefault();
                                        }
                                    }}
                                />
                                <FormHelperText style={paymentQuantityError.isError ? { color: 'red', fontWeight: 'bold' } : {}}>
                                    {paymentQuantityError.isError === false
                                        ? MESSAGES.QUANTITY_TO_PAY
                                        : paymentQuantityError.message}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box display="flex"
                            flexDirection={isMobile ? "row" : "column"}
                            justifyContent="space-between"
                            marginBottom={2}
                            gap={isMobile ? 0 : 2} >
                            <FormControl sx={{ m: isMobile ? 1 : 0, width: isMobile ? '32ch' : '100%' }} variant="outlined" disabled={isSubmitting || shipNumberError.hasNoShip}>
                                <OutlinedInput
                                    required
                                    id="outlined-required"
                                    name="percentageOfCodValueOffered"
                                    readOnly
                                    value={percentageOfCodValueOffered !== null && percentageOfCodValueOffered !== undefined ? formatNumber(percentageOfCodValueOffered.toString()) : ""}
                                    error={percentageOfCodValueOfferedError.isError}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {isFetchValueCoefficientAndPercentage ? <CircularProgress size={20} /> : '%'}
                                        </InputAdornment>
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <MdPieChart />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText style={percentageOfCodValueOfferedError.isError ? { color: 'red', fontWeight: 'bold' } : {}}>
                                    {percentageOfCodValueOfferedError.isError === false
                                        ? MESSAGES.COD_RATIO_TO_PAY
                                        : percentageOfCodValueOfferedError.message}
                                </FormHelperText>
                            </FormControl>
                            <FormControl sx={{ m: isMobile ? 1 : 0, width: isMobile ? '32ch' : '100%' }} variant="outlined" disabled={isSubmitting || shipNumberError.hasNoShip}>
                                <OutlinedInput
                                    required
                                    id="outlined-required-value-coefficient"
                                    name="valueCoefficient"
                                    readOnly
                                    value={valueCoefficient !== null && valueCoefficient !== undefined ? formatNumberFloat(valueCoefficient.toString()) : ""}
                                    error={valueCoefficientError && valueCoefficientError.isError}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            {isFetchValueCoefficientAndPercentage ? <CircularProgress size={20} /> : ''}
                                        </InputAdornment>
                                    }
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <MdPieChart />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText style={valueCoefficientError && valueCoefficientError.isError ? { color: 'red', fontWeight: 'bold' } : {}}>
                                    {valueCoefficientError && valueCoefficientError.isError === false
                                        ? MESSAGES.VALUE_COEFFICIENT_MESSAGE
                                        : valueCoefficientError.message}
                                </FormHelperText>
                            </FormControl>
                        </Box>
                        <Box marginBottom={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="acknowledgment"
                                        color="primary"
                                        onChange={handleCheckboxChange}
                                        checked={isChecked}
                                    />
                                }
                                label="Tilboð þetta er rétt og ég geri mér grein fyrir að það er bindandi."
                            />
                        </Box>
                        <DialogActions>
                            <Button disabled={isSubmitting} onClick={() => onClose(false)}>Loka</Button>
                            <Button type="submit" disabled={isSubmitting || shipNumberError.hasNoShip || !isChecked}>Skrá tilboð</Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}
